// Imports base styles and the router component

import './App.scss';
import MyRouter from './MyRouter';

function App() {
  return (
    <MyRouter />
  );
}

export default App;
