import React from "react";

const Login = () => {
  return (
    <div>
      <h1>Login Page</h1>
      {/* Add your login form or login-related content here */}
    </div>
  );
};

export default Login;
