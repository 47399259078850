import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MyNavbar from './MyNavbar';
import MyFooter from './MyFooter';
import Home from './Home/Home';
import Blog from './Pages/Blog';
import Portfolio from './Pages/Portfolio';
import Login from './Pages/Login';
import AboutUs from './Pages/AboutUs';
import Tutorials from './Pages/Tutorials';
import NotFound from './Pages/NotFound';
import Demo from './Pages/Demo';
import UniversalAiTutor from './Pages/UniversalAiTutor';

export default function MyRouter() {
  return (
    <BrowserRouter>
      <MyNavbar />
      <main id="main" className="flex-grow-1 bg-primary text-light">
        <div className="page-content"> {/* Wrap the content area with the new class */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/tutorials" element={<Tutorials />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/universal-ai-tutor" element={<UniversalAiTutor/>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </main>
      <div className="footer-container"> {/* Wrap the footer with a new container */}
        <MyFooter />
      </div>
    </BrowserRouter>
  );
}
