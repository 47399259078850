import React from 'react';
import { Link } from 'react-router-dom';

export default function MyFooter() {
    return (
        <footer className="footer mt-auto py-3">
            {/* <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <h5>Section 1</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/" className="text-muted">Home</Link></li>
                            <li><Link to="/features" className="text-muted">Features</Link></li>
                            <li><Link to="/pricing" className="text-muted">Pricing</Link></li>
                            <li><Link to="/faqs" className="text-muted">FAQs</Link></li>
                            <li><Link to="/about" className="text-muted">About</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h5>Section 2</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/section2/home" className="text-muted">Home</Link></li>
                            <li><Link to="/section2/features" className="text-muted">Features</Link></li>
                            <li><Link to="/section2/pricing" className="text-muted">Pricing</Link></li>
                            <li><Link to="/section2/faqs" className="text-muted">FAQs</Link></li>
                            <li><Link to="/section2/about" className="text-muted">About</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h5>Section 3</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/section3/home" className="text-muted">Home</Link></li>
                            <li><Link to="/section3/features" className="text-muted">Features</Link></li>
                            <li><Link to="/section3/pricing" className="text-muted">Pricing</Link></li>
                            <li><Link to="/section3/faqs" className="text-muted">FAQs</Link></li>
                            <li><Link to="/section3/about" className="text-muted">About</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h5>Subscribe to our newsletter</h5>
                        <p>Monthly digest of what's new and exciting from us.</p>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Email address" />
                            <button className="btn btn-primary" type="button">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container text-center">
                <p>&copy; 2023 Halcyox All rights reserved.</p>
                <ul className="list-unstyled d-flex justify-content-center">
                    <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use xlinkHref="#twitter"></use></svg></a></li>
                    <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use xlinkHref="#instagram"></use></svg></a></li>
                    <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use xlinkHref="#facebook"></use></svg></a></li>
                </ul>
            </div> */}
        </footer>
    );
}
