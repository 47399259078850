import React, { useEffect } from 'react';
import '../PagesStyling/UniversalAiTutor.css'; // Import the CSS file for styling
import feature1 from '../images/feature1.png';
import feature2 from '../images/feature2.png';
import feature3 from '../images/feature4.png';
import demoVideo from '../images/demo1.mp4'; // Import the local video file

const UniversalAiTutor: React.FC = () => {
  useEffect(() => {
    // Add your server-side client logic for the personalized AI Tutor here
    // This code will run when the component mounts
    // You can connect to the AI Tutor server, fetch personalized data, etc.
  }, []);

  const youtubeVideoUrl = 'https://www.youtube.com/embed/mESKg9TBqT0';

  return (
    <div className="universal-ai-tutor">
      <div className="universal-ai-tutor__content">

      <section className="universal-ai-tutor__additional-content">
          <div className="universal-ai-tutor__additional-content-container">

          </div>
        </section>

        <section className="universal-ai-tutor__hero">
          <div className="universal-ai-tutor__hero-content">
            <h1 className="universal-ai-tutor__hero-title">Make Learning More Fun With The Universal AI Tutor</h1>
            <p className="universal-ai-tutor__hero-subtitle">Your Personalized AI-Powered Learning Companion</p>
          </div>
        </section>

        <section className="universal-ai-tutor__features">
          <div className="universal-ai-tutor__feature-item">
            <img src={feature1} alt="Feature 1" className="universal-ai-tutor__feature-icon" />
            <h2 className="universal-ai-tutor__feature-title">Tailored Learning Experience</h2>
            <p className="universal-ai-tutor__feature-description">
              With our Universal AI Tutor, your learning experience is fully personalized. Our advanced AI algorithms analyze your strengths, weaknesses, and learning style to create a customized curriculum just for you.
            </p>
          </div>

          <div className="universal-ai-tutor__feature-item">
            <img src={feature2} alt="Feature 2" className="universal-ai-tutor__feature-icon" />
            <h2 className="universal-ai-tutor__feature-title">Interactive Lessons</h2>
            <p className="universal-ai-tutor__feature-description">
              Engage with interactive lessons that adapt to your progress. Our AI Tutor provides real-time feedback, adapts the difficulty level based on your performance, and ensures an engaging and effective learning process.
            </p>
          </div>

          <div className="universal-ai-tutor__feature-item">
            <img src={feature3} alt="Feature 3" className="universal-ai-tutor__feature-icon" />
            <h2 className="universal-ai-tutor__feature-title">24/7 Support</h2>
            <p className="universal-ai-tutor__feature-description">
              Our AI Tutor is available to assist you anytime, anywhere. Whether you have a question or need guidance, our virtual assistant is there to provide immediate and personalized support, ensuring you never feel stuck.
            </p>
          </div>
        </section>

        <section className="universal-ai-tutor__testimonial">
          <div className="universal-ai-tutor__testimonial-content">
            <p className="universal-ai-tutor__testimonial-quote">
              "The idea for Universal AI Tutor came about when I was tinkering with language models over the past few years, and I found a way to use characters to make that process of learning quite a bit more fun. Our innate curiosity as human beings make us want to explore across domains. Existing educational equities persist despite the fact that we have the technology to make learning more accessible and engaging. I believe that Universal AI Tutor can help bridge that gap. I'd love your feedback on how we can make this tool better!"
            </p>
            <p className="universal-ai-tutor__testimonial-author">- Alif Jakir, Student & Founder</p>
          </div>
        </section>

        

        <section className="universal-ai-tutor__call-to-action">
          <div className="universal-ai-tutor__cta-content">
            <h2 className="universal-ai-tutor__cta-title">Ready to Unlock Your Learning Potential?</h2>
            <p className="universal-ai-tutor__cta-description">Join the waitlist and fill out the questionnaire below!</p>
            <button className="universal-ai-tutor__cta-button"><a href={"https://tally.so/r/mK5qQX"} className="learn-more-button">Get Started</a></button>
            
          </div>
        </section>

        {/* Embed the YouTube video */}
        <section className="universal-ai-tutor__demo-video">
          <div className="universal-ai-tutor__demo-video-content">
            <h2 className="universal-ai-tutor__demo-video-title">See Universal AI Tutor in Action</h2>
            <div className="universal-ai-tutor__demo-video-container">
              <iframe
                width="560" // Set the width as needed
                height="315" // Set the height as needed
                src={youtubeVideoUrl}
                frameBorder="0"
                allowFullScreen
                title="Universal AI Tutor Demo Video"
              ></iframe>
            </div>
          </div>
        </section>

        <section className="universal-ai-tutor__additional-content">
          <div className="universal-ai-tutor__additional-content-container">
            
            <p className="universal-ai-tutor__additional-content-description">
              The above utilizes auto animation and auto sound synthesis. This page is a Work in Progress.
            </p>
          </div>
        </section>

      </div>
    </div>
  );
}

export default UniversalAiTutor;
