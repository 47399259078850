import React from "react";

const Portfolio = () => {
  return (
    <div>
      <h1>Portfolio Page</h1>
      {/* Add your portfolio content here */}
    </div>
  );
};

export default Portfolio;
