import React, { useRef } from "react";
import { Canvas, useFrame } from "react-three-fiber";
import { Box, Sphere, OrbitControls } from "@react-three/drei";
import "../PagesStyling/AboutUs.css";

const Animation = () => {
  const cubeRef = useRef<any>();
  const sphereRefs = useRef<any[]>([]);
  const lightRefs = useRef<any[]>([]);
  const COLORS = [
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "indigo",
    "violet",
  ];

  useFrame((state) => {
    const time = state.clock.getElapsedTime();

    if (cubeRef.current) {
      cubeRef.current.rotation.x += 0.01;
      cubeRef.current.rotation.y += 0.01;
      cubeRef.current.rotation.z += 0.01;
    }

    sphereRefs.current.forEach((sphere, index) => {
      if (sphere) {
        const radius = 1 + Math.random();
        const angle = time * 0.1 + index * (Math.PI / sphereRefs.current.length);
        sphere.position.x = Math.cos(angle) * radius;
        sphere.position.y = Math.sin(angle) * radius;
        sphere.rotation.x += 0.01;
        sphere.rotation.y += 0.01;
      }
    });

    lightRefs.current.forEach((light, index) => {
      if (light) {
        const radius = 5;
        const angle = time * 0.1 + index * (Math.PI / lightRefs.current.length);
        light.position.x = Math.cos(angle) * radius;
        light.position.y = Math.sin(angle) * radius;
        light.position.z = Math.sin(angle) * radius;
      }
    });
  });

  return (
    <>
      <ambientLight intensity={0.2} />
      {COLORS.map((color, i) => (
        <pointLight
          key={i}
          color={color}
          distance={10}
          intensity={1}
          position={[2, 0, 0]}
          ref={(ref) => (lightRefs.current[i] = ref)}
        />
      ))}

      <group ref={cubeRef}>
        <Box args={[2, 2, 2]}>
          <meshStandardMaterial color={0x00ff00} metalness={1} roughness={0.1} />
        </Box>
      </group>

      {[...Array(10)].map((_, i) => (
        <Sphere
          key={i}
          args={[0.5, 16, 16]}
          position={[Math.random() * 2 - 1, Math.random() * 2 - 1, Math.random() * 2 - 1]}
          ref={(ref) => (sphereRefs.current[i] = ref)}
        >
          <meshStandardMaterial color={0xff0000} metalness={1} roughness={0.1} />
        </Sphere>
      ))}

      <OrbitControls autoRotate />
    </>
  );
};

const AboutUs = () => {
  return (
    <div className="about-us-wrapper">
      <div className="about-us-container">
        <div className="about-us-content">
          <h1>About Us</h1>
          <p>
            At Halcyox, we are a group of passionate individuals dedicated to democratizing access to advanced Superintelligence. Our mission is to create tools that augment human intelligence and increase collective problem-solving capacities.
          </p>
          <p>
            Founded with the purpose of harnessing the upcoming capabilities of the AI revolution, we strive to develop integrated AI systems that enable us to think more deeply, more wisely, and more critically. We believe that by combining the power of human intellect with the potential of artificial intelligence, we can unlock new frontiers of knowledge and innovation.
          </p>
          <p>
            Our team is committed to pushing the boundaries of what is possible with AI. We work tirelessly to develop innovative solutions that empower individuals and organizations to solve complex challenges and make informed decisions. Through our cutting-edge technologies and research, we aim to bridge the gap between human potential and the limitless possibilities offered by AI.
          </p>
          <p>
            As we continue on our journey, we invite you to join us in shaping the future of intelligence. Together, we can create a world where advanced AI is accessible to all, driving positive transformations and advancing humanity as a whole.
          </p>
          <p>
            We're starting with education, where we think the upsides possible are tremendous. 
          </p>
          <h1>Team</h1>
          <p>
            Alif Jakir - Founder & CEO | <a href="https://www.alifjakir.com/">Site</a> | <a href="https://github.com/Caerii">Github</a> | <a href="https://www.linkedin.com/in/alif-jakir/">LinkedIn</a>
            <br></br>
            AJ Lombardi - Co-Founder & CTO | <a href="https://github.com/NicerNewerCar">Github</a> | <a href="https://www.linkedin.com/in/anthony-lombardi-287335187/">LinkedIn</a>
            <br></br>
            Ember Arlynx - Co-Founder & Software Lead | <a href="https://ember.software/">Site</a> | <a href="https://www.linkedin.com/in/emberarlynx/">LinkedIn</a>
            <br></br>
            Marinel Tinnirello - UI Design Engineer | <a href="http://www.marineltinnirello.com/">Site</a> | <a href="https://www.linkedin.com/in/marinel-tinnirello/">LinkedIn</a>
            <br></br>
            
          </p>
        </div>
        <div className="about-us-3d-container">
          <Canvas>
            <Animation />
          </Canvas>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
