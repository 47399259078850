import React, { useState, useRef } from "react";
import { Canvas, useFrame, useThree } from "react-three-fiber";
import { Html, Shadow, Text, OrbitControls } from "@react-three/drei";
import { gsap } from "gsap";
import { Object3D, PerspectiveCamera, Vector3, Euler, Color, MathUtils, BufferGeometry, Material, Mesh, MeshBasicMaterial, BoxGeometry } from "three";
import { EffectComposer, SSAO } from "@react-three/postprocessing";
import "../PagesStyling/Blog.css";

interface Post {
  id: number;
  title: string;
  description: string;
  tags: string[];
  content: string;
}

interface TileProps {
  position: [number, number, number];
  onClick: (position: [number, number, number]) => void;
  post: Post;
  active: boolean;
}


const Tile: React.FC<TileProps> = ({ position, onClick, post, active }) => {
  const meshRef = useRef<Mesh<BufferGeometry, Material> | null>(null);

  const [hovered, setHovered] = useState(false);
  const [wobble, setWobble] = useState(0);

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.y += wobble;
    }
  });

  const handleWobble = () => {
    setWobble(active ? 0.01 : 0.05);
    setTimeout(() => {
      setWobble(0);
    }, 200);
  };

  const facePositions: { position: Vector3; rotation: Euler }[] = [
    { position: new Vector3(0, 0, 0.6), rotation: new Euler() },    // Front face
    { position: new Vector3(0, 0, -0.6), rotation: new Euler(0, Math.PI, 0) },   // Back face
    { position: new Vector3(0.6, 0, 0), rotation: new Euler(0, Math.PI / 2, 0) },    // Right face
    { position: new Vector3(-0.6, 0, 0), rotation: new Euler(0, -Math.PI / 2, 0) },   // Left face
  ];

  return (
    <group position={position}>
      <mesh
        ref={meshRef}
        onClick={() => {
          onClick(position);
          handleWobble();
        }}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
        castShadow // Enable shadow casting
      >
        <boxGeometry args={[1, 1, 1]} />
        <meshBasicMaterial attach="material" color={active ? "orange" : hovered ? "gray" : "lightgray"} />

        {facePositions.map(({ position, rotation }, index) => (
          <group key={index} position={position} rotation={rotation}>
            {hovered || active ? (
              <Html center position={[0, 0, 0.8]}>
                <div className="blog-details">
                  <h2>{post.title}</h2>
                  <p>{post.description}</p>
                  <div className="tags">
                    {post.tags.map((tag, index) => (
                      <span key={index}>{tag}</span>
                    ))}
                  </div>
                  <a href={post.content} target="_blank" rel="noopener noreferrer">Read More</a>
                </div>
              </Html>
            ) : (
              <Text color="black" fontSize={0.2}>
                {post.title}
              </Text>
            )}
          </group>
        ))}
      </mesh>
      <Shadow position={[0, -0.5, 0]} rotation={[-Math.PI / 2, 0, 0]} opacity={0.5} />
    </group>
  );
};

interface AnimationProps {
  blogPosts: Post[];
}

const Animation: React.FC<AnimationProps> = ({ blogPosts }) => {
  const [activeTile, setActiveTile] = useState<number | null>(null);
  const { camera } = useThree();

  const handleClick = (id: number, position: [number, number, number]) => {
    setActiveTile(activeTile === id ? null : id);

    const targetPosition = new Vector3(...position);
    const zoomOutPosition = new Vector3(targetPosition.x, targetPosition.y, targetPosition.z + 5); // Add 5 units offset along z-axis

    // Create a dummy object to calculate the rotation
    const dummyObject = new Object3D();
    dummyObject.position.copy(camera.position);
    dummyObject.lookAt(targetPosition);
    const targetRotation = new Euler().setFromQuaternion(dummyObject.quaternion, 'YXZ');

    // Create a timeline for the camera movements
    const tl = gsap.timeline();

    tl.to(camera, { fov: 95, duration: 1 }) // zoom out
      .to(camera.position, { duration: 2, x: zoomOutPosition.x, y: zoomOutPosition.y, z: zoomOutPosition.z, ease: "power2.inOut" }) // move to zoomed out position
      .to(camera.rotation, { duration: 1, x: targetRotation.x, y: targetRotation.y, z: targetRotation.z, ease: "power1.inOut" }) // rotate to target
      //fly up and out
      .to(camera.position, { duration: 2, x: 4, y: 4, z: 0, ease: "power2.inOut" })
    };

  return (
    <>
      {blogPosts.map((post, index) => {
        const spawnHeight = -index * 2 + 2; // Adjust the spawn height here

        return (
          <Tile
            key={post.id}
            position={[
              MathUtils.randFloatSpread(1.5), // x
              spawnHeight,
              MathUtils.randFloatSpread(0.5), // z
            ]}
            onClick={(position) => handleClick(post.id, position)}
            post={post}
            active={activeTile === post.id}
          />
        );
      })}
    </>
  );
};

const Blog: React.FC = () => {
  const blogPosts: Post[] = [
    {
      id: 1,
      title: "Post 1",
      description: "This is the description of post 1.",
      tags: ["tag1", "tag2"],
      content: "https://example.com/post1",
    },
    {
      id: 2,
      title: "Post 2",
      description: "This is the description of post 2.",
      tags: ["tag3", "tag4"],
      content: "https://example.com/post2",
    },
    {
      id: 3,
      title: "Post 3",
      description: "This is the description of post 3.",
      tags: ["tag5", "tag6"],
      content: "https://example.com/post3",
    },
  ];

  const [selectedPost, setSelectedPost] = useState<Post | null>(null);

  const handleClick = (id: number, position: [number, number, number]) => {
    const post = blogPosts.find(post => post.id === id);
    if (post) {
      setSelectedPost(post);
    }
  };

  const SelectedPost: React.FC<{ post: Post | null }> = ({ post }) => {
  
    const selectedPostRef = useRef<HTMLDivElement>(null);
  
    const handleMouseOver = () => {
      if (post && selectedPostRef.current) {
        selectedPostRef.current.style.transform = 'scale(1.1)';
      }
    };
  
    const handleMouseOut = () => {
      if (post && selectedPostRef.current) {
        selectedPostRef.current.style.transform = 'scale(1.05)';
      }
    };
  
    if (!post) return null;
  
    return (
      <div 
        className="selected-post" 
        ref={selectedPostRef}
        style={{
          position: 'absolute', 
          top: '10px', 
          left: '10px', 
          padding: '20px', 
          borderRadius: '15px',
          backgroundColor: 'white',
          boxShadow: '5px 5px 15px rgba(0,0,0,0.1)',
          transition: '0.3s',
          transform: 'scale(1.05)',
          zIndex: 9999,
        }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <h2>{post.title}</h2>
        <p>{post.description}</p>
        <div className="tags">
          {post.tags.map((tag, index) => (
            <span key={index}>{tag}</span>
          ))}
        </div>
        <a href={post.content} target="_blank" rel="noopener noreferrer">Read More</a>
      </div>
    );
  };
  
  return (
    <div className="blog-container">
      <h1>Blog Page</h1>
      <SelectedPost post={selectedPost} />
      <div className="blog-3d-container">
      <Canvas>
        <OrbitControls />
        <ambientLight intensity={0.5} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} castShadow />
        <Animation blogPosts={blogPosts} />
        <EffectComposer>
          <SSAO
            samples={31}
            radius={10}
            intensity={30}
            luminanceInfluence={0.6}
            color={new Color('black')}
            worldDistanceThreshold={0.1}
            worldDistanceFalloff={0.2}
            worldProximityThreshold={0.5}
            worldProximityFalloff={0.1}
          />
        </EffectComposer>
      </Canvas>
      </div>
    </div>
  );
};

export default Blog;