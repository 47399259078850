import React, { useState } from "react";
import "../PagesStyling/Tutorials.css";

interface Tutorial {
  id: number;
  title: string;
  description: string;
  details: string;
  link: string;
  image: string;
  isExpanded: boolean;
}

const Tutorials = () => {
  const [resources, setResources] = useState<Tutorial[]>([
    {
      id: 1,
      title: "Resource 1",
      description: "Meta-learning concepts and strategies for effective learning with artificial coreasoners",
      details: "What is meta-learning? How can AI augment your ability to engage in it? This resource will introduce you to the fundamentals of meta-learning and how to apply them to accelerate your learning process. You will learn how to leverage multimodal foundation models to extract key insights, generate summaries, and create visualizations to enhance your understanding and retention of information.",
      link: "https://www.halcyox.com/resource1",
      image: "resource1.png",
      isExpanded: false,
    },
    {
      id: 2,
      title: "Resource 2",
      description: "Open-source master-list of experimental human augmentation technology.",
      details: "",
      link: "https://www.halcyox.com/resource2",
      image: "resource2.png",
      isExpanded: false,
    },
    {
      id: 3,
      title: "Resource 3",
      description: "",
      details: "",
      link: "https://www.halcyox.com/resource3",
      image: "resource3.png",
      isExpanded: false,
    },
    // Add more resources as needed
  ]);

  const [tutorials, setTutorials] = useState<Tutorial[]>([
    {
      id: 1,
      title: "Accelerating learning using multimodal foundation models",
      description: "Meta-learning concepts and strategies for effective learning with artificial coreasoners",
      details: "This tutorial will introduce you to the fundamentals of meta-learning and how to apply them to accelerate your learning process. You will learn how to leverage multimodal foundation models to extract key insights, generate summaries, and create visualizations to enhance your understanding and retention of information.",
      link: "https://www.halcyox.com/tutorial1",
      image: "tutorial1.png",
      isExpanded: false,
    },
    {
      id: 2,
      title: "Master the art of critical thinking with generative strategies",
      description: "Explore generative thinking techniques to expand your critical thinking skills",
      details: "Delve into the principles and techniques of generative thinking, enabling you to approach problems and challenges from creative and innovative angles. Unleash your potential by adopting generative strategies to analyze, evaluate, and synthesize information effectively.",
      link: "https://www.halcyox.com/tutorial2",
      image: "tutorial2.png",
      isExpanded: false,
    },
    {
      id: 3,
      title: "Unlock your problem-solving potential using Multimodal GPT",
      description: "Leverage Multimodal GPT to tackle complex problems and find creative solutions",
      details: "Uncover the power of Multimodal GPT as a problem-solving tool. In this tutorial, you will learn how to leverage Multimodal GPT's capabilities to tackle complex problems and find creative solutions. Through practical examples and exercises, you will gain insights into using Multimodal GPT as a valuable resource for expanding your problem-solving potential.",
      link: "https://www.halcyox.com/tutorial3",
      image: "tutorial3.png",
      isExpanded: false,
    },
    {
      id: 4,
      title: "Improve your learning efficiency with Multimodal GPT",
      description: "Discover strategies for optimizing your learning process using Multimodal GPT",
      details: "Discover strategies for optimizing your learning process using Multimodal GPT. This tutorial will cover techniques for extracting key insights, generating summaries, and creating visualizations to enhance your understanding and retention of information. Learn how to make the most of Multimodal GPT to accelerate your learning journey.",
      link: "https://www.halcyox.com/tutorial4",
      image: "tutorial4.png",
      isExpanded: false,
    },
    // Add more tutorials as needed
  ]);

  const [expandedTileId, setExpandedTileId] = useState<number | null>(null);

  const toggleTutorialExpansion = (id: number, isResource: boolean) => {
    if (isResource) {
      setResources((prevResources) =>
        prevResources.map((resource) =>
          resource.id === id ? { ...resource, isExpanded: !resource.isExpanded } : resource
        )
      );
    } else {
      setTutorials((prevTutorials) =>
        prevTutorials.map((tutorial) =>
          tutorial.id === id ? { ...tutorial, isExpanded: !tutorial.isExpanded } : tutorial
        )
      );
    }
  };

  return (
    <div className="tutorials-container">
      <h1>Instructional Resources</h1>
      <div className="tutorials-grid">
        {resources.map((resource) => (
          <div
            className={`tutorial-tile ${resource.isExpanded ? "expanded" : ""}`}
            key={resource.id}
            onClick={() => toggleTutorialExpansion(resource.id, true)}
          >
            <h3>{resource.title}</h3>
            <p>{resource.description}</p>
            {resource.isExpanded && (
              <div className="tutorial-details-wrapper">
                <p className="tutorial-details">{resource.details}</p>
                <a href={resource.link} target="_blank" rel="noopener noreferrer">
                  Go to resource
                </a>
                <img src={resource.image} alt={resource.title} />
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="section-gap" />

      <h1>Tutorials</h1>
      <div className="tutorials-grid">
        {tutorials.map((tutorial) => (
          <div
            className={`tutorial-tile ${tutorial.isExpanded ? "expanded" : ""}`}
            key={tutorial.id}
            onClick={() => toggleTutorialExpansion(tutorial.id, false)}
          >
            <h3>{tutorial.title}</h3>
            <p>{tutorial.description}</p>
            {tutorial.isExpanded && (
              <div className="tutorial-details-wrapper">
                <p className="tutorial-details">{tutorial.details}</p>
                <a href={tutorial.link} target="_blank" rel="noopener noreferrer">
                  Go to tutorial
                </a>
                <img src={tutorial.image} alt={tutorial.title} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tutorials;