import React from 'react';
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from './logo.gif'

export default function MyNavbar() {
  return (
    <Navbar bg="primary" variant="dark" expand="md">
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" as="ul">
            {/* <Nav.Item as="li">
              <Nav.Link as={Link} to="portfolio">Portfolio</Nav.Link>
            </Nav.Item> */}
            <Nav.Item as="li">
              <Nav.Link as={Link} to="about-us">About Us</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as={Link} to="tutorials">Tutorials</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as={Link} to="blog">Blog</Nav.Link>
            </Nav.Item>
            {/* <Nav.Item as="li">
              <Nav.Link as={Link} to="login">Login</Nav.Link>
            </Nav.Item> */}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Brand as={Link} to="/" className="ml-auto">
          <img
            src={logo}
            alt="fail"
            width="30"
            height="30"
            className="align-top img-fluid"
            onError={(e) => {
              e.currentTarget.style.setProperty("display", "none", "important");
              console.log("Error loading image at", e.currentTarget.src);
            }}
          />
          &nbsp;Halcyox
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}
