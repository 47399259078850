import React, { useEffect, useRef, useState, FormEvent } from 'react';
import '../PagesStyling/Demo.css'; // assuming you have a CSS file for styling

declare global {
  interface Window {
    IVSPlayer: any;
  }
}

type Message = {
  text: string;
  sender: string;
};

const Demo = () => {
  const videoRef = useRef(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://player.live-video.net/1.11.0/amazon-ivs-player.min.js';
    script.async = true;
    script.onload = () => initPlayer();
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initPlayer = () => {
    if (!window.IVSPlayer.isPlayerSupported) {
      alert('Your browser does not support the IVS video player. Please try a different browser.');
      return;
    }

    // AUDIO2FACE URL is HERE
    const streamUrl = 'https://e240bd169bc0.us-east-1.playback.live-video.net/api/video/v1/us-east-1.319933937176.channel.au4ocyoV2Kyx.m3u8';
    const ivsPlayer = window.IVSPlayer.create();

    // Add the error event listener here
    ivsPlayer.addEventListener(window.IVSPlayer.PlayerEventType.ERROR, (error: Error) => {
        console.error('Player error:', error);
    });

    ivsPlayer.attachHTMLVideoElement(videoRef.current);
    ivsPlayer.load(streamUrl);
    ivsPlayer.play();
  };

  const handleSendMessage = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (input.trim() === '') return;
  
    const newMessage: Message = {
      text: input,
      sender: 'user',
    };
  
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInput('');
  
    // try { //localhost code
    //   const response = await fetch('http://172.20.128.1:5000/api/converse', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ text: input }),
    //   });

    try {
        const response = await fetch('http://192.168.12.228:5000/api/converse', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ text: input }),
        });
  
      const data = await response.json();
      console.log(data); // DEBUGGING
  
      setTimeout(() => {
        const aiMessage: Message = {
          text: data.text,
          sender: 'character',
        };
  
        setMessages((prevMessages) => [...prevMessages, aiMessage]);
      }, 1000); // Delay of 1 second
    } catch (error) {
      const aiMessage: Message = {
        text: 'Could not connect to server.',
        sender: 'character',
      };
  
      setMessages((prevMessages) => [...prevMessages, aiMessage]);
    }
  };
  

  return (
    <div className="chat-container">
      <video ref={videoRef} controls autoPlay playsInline className="video-player" />
  
      <div className="disclaimer">
        <p className="disclaimer-text">If you run into bugs, close the tab and re-open the demo.</p>
      </div>
  
      <div className="chat-messages">
        {messages.map((message, index) => (
          <p key={index} className={`chat-message ${message.sender}`}>
            {message.text}
          </p>
        ))}
      </div>
  
      <form className="chat-form" onSubmit={handleSendMessage}>
        <input
          type="text"
          className="chat-input"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button type="submit" className="chat-submit-button">
          Send
        </button>
      </form>
    </div>
  );
  
};

export default Demo;
