import { Component } from "react";
import { TypeAnimation } from 'react-type-animation';
import { useNavigate } from 'react-router-dom';
import aiImage from '../images/ai.png';
import discordLogo from '../images/discord2.svg';
import '../PagesStyling/Home.css'; // Import the CSS file for styling

interface CardProps {
  title: string;
  subtitle: string;
  img: string;
  demoLink: string;
  learnMoreLink: string;
}

class Home extends Component {
  render() {
    return (
      <div className="home-container"> {/* Apply the "home-container" class */}
        <Jumbotron />
        <Card
          title={"Universal AI Tutor"}
          subtitle={"An easy-to-use, universally accessible AI tutor that can instruct on any subject on demand."}
          img={aiImage}
          demoLink={"/demo"}
          learnMoreLink={"/universal-ai-tutor"}
        />
      </div>
    );
  }
}

function Title() {
  return (
    <TypeAnimation
      sequence={[' Architecting AI solutions that augment human intelligence.', 100000, '']}
      speed={75} // Must be in range between 1 and 99!
      wrapper="h2"
      repeat={Infinity}
      className="main-title"
    />
  );
}

function Jumbotron() {
  const navigate = useNavigate();
  const navigateAbout = () => {
    navigate('/about-us');
  };

  const joinDiscord = () => {
    // Add your Discord invitation link here
    window.open('https://discord.gg/ZxprFEKUQS', '_blank');
  }

  return (
    <div className="jumbotron">
      <Title />
      <Subtitle />
      <div className="button-container">
        <button className="learn-more-button" onClick={navigateAbout} style={{ marginTop: '20px' }}>
          Learn More
        </button>
      </div>

    </div>
  );
}

function Subtitle() {
  return (
    <h6>
      We're on a quest to democratize human augmentation through AI.
    <br></br>
    <br></br>
      This starts with augmenting how we learn.
    <br></br>
    <br></br>
        Join us on our journey.
    </h6>
  );
}

function Card(props: CardProps) {
    const navigateToDemo = () => {
      window.open(props.demoLink, '_blank');
    };
  
    return (
      <div className="main-card">
        <h1>{props.title}</h1>
        <img src={props.img} alt={`image of ${props.title}`} />
        <h4>{props.subtitle}</h4>
        <div className="card-buttons">
          <button className="demo-button" onClick={navigateToDemo}>Demo</button>
          <a href={props.learnMoreLink} className="learn-more-button">Learn More</a>
        </div>
      </div>
    );
  }
  

function openModal(videoSrc: string) {
  let background = document.createElement("div");
  background.className = "modal-background";
  background.onclick = closeModal;

  let video = document.createElement("iframe");
  video.className = "modal-vid";
  video.width = "500px";
  video.height = "350px";
  video.src = videoSrc;
  video.onclick = () => {};

  background.append(video);
  const mainElement = document.getElementById("main");
  if (mainElement) {
    mainElement.append(background);
  }

  function closeModal() {
    background.remove();
  }
}

export default Home;
